import styled from "styled-components";

const ParagraphWrapper = styled.div`
    background-color: #fff;
    box-shadow: 0px 0px 13px 6px #bfbfbf0f;
    border-radius: 10px;
    padding: 15px 28px;
    margin-bottom: 30px;

    h2 {
        font-size: 35px;
        font-weight: 400;
        margin: 0 0 2px 0;
        color: #0f2137;
        @media only screen and (max-width: 1366px) {
            font-size: 32px;
            line-height: 40px;
        }
        @media only screen and (max-width: 667px) {
            font-size: 28px;
            line-height: 38px;
        }
    }

    ul {
        display: block;
        list-style: disc inside none;
    }

    li {
        padding-right: 17px;

        &:before {
            content: "•";
            position: absolute;
            display: inline-block;
            font-size: 27px;
            margin-top: -4px;
            margin-right: -17px;
        }
    }

    p {
        margin: 0;
        font-size: 20px;
        color: #484848;
    }

    dt {
        margin-top: 12px;
        font-size: 22px;
        font-weight: 600;
        color: #0f2137;
    }

    dd {
        margin-bottom: 6px;
    }

    .inline-block {
        display: block;
    }

    .bold {
        font-weight: 800;
        color: #000;
    }
`;

export default ParagraphWrapper;
