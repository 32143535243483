import React from "react";
import ParagraphWrapper from "./Paragraph.style";

export default function Paragraph({ title, children }) {
    return (
        <ParagraphWrapper>
            <h2>{title}</h2>
            <p>{children}</p>
        </ParagraphWrapper>
    );
}
