import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import Sticky from 'react-stickynode';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import Paragraph from '../containers/Paragraph';
import Container from '../common/components/UI/Container';
import Footer from '../containers/Footer';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO title={__('الشروط و الأحكام', 'Terms and Conditions')} />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>

                <Container
                    style={{
                        marginTop: 150,
                    }}
                >
                    <Paragraph title={__('مقدمة', 'Introduction')}>
                        {__(
                            <>
                                نعتز بزيارتكم لتطبيق "أواصل AWASUL". نقدم من
                                خلال تطبيق أواصل مجموعة متنوعة من منتجات شركة
                                تنشيط لتقنية المعلومات ذ.م.م ونتيح طلب خدماتنا
                                أون لاين من خلال التطبيق مع توفير وسائل دفع
                                إلكترونية وسياسات كاملة لتنظيم عملية البيع.
                                ونقدم هذه الشروط والأحكام لتحكم وتنظم العلاقات
                                القانونية بين تطبيق أواصل ومستخدميه، لذا يرجى
                                قراءة هذه الشروط بالعناية اللازمة قبل استخدام
                                التطبيق.
                            </>,
                            `We are proud of your visit to AWASUL application. Through AWASUL application we offer a variety of Energize Information Technology Company LTD products and allow to request our services online through the application while providing electronic payment methods and complete policies to regulate the sale process. We provide these terms and conditions to govern and regulate the legal relationships between The AWASUL application and its users, so please read these terms in an appropriate attention before using the application.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('التعريفات', 'Definitions')}>
                        {__(
                            <>
                                "أواصل" أو "التطبيق" أو "نحن" أو "ضمائر المخاطب
                                والملكية" يشير إلى تطبيق أواصل AWASUL، وشركة
                                تنشيط لتقنية المعلومات ذ.م.م بالمملكة العربية
                                السعودية. "الاتفاقية" يشير إلى هذه الوثيقة وما
                                تتضمنه من شروط وأحكام، وكذلك سياسة الخصوصية.
                                "المستخدم" أو "أنت" أو "ضمائر المخاطب" يشير إلى
                                الشخص الذي يستخدم تطبيق أواصل أو يقوم بزيارته أو
                                زيارة المحتوى المتاح من خلال التطبيق. "العميل"
                                يشير إلى الأفراد والمؤسسات والشركات والهيئات
                                العامة والخاصة التي تقدم طلبات شراء البرامج
                                والمنتجات التي يوفرها التطبيق وفق شروط وأحكام
                                هذه الاتفاقية. "البرنامج" أو "المنتج" يشير إلى
                                البرامج التي يعرضها التطبيق للبيع والتي يتم
                                توفيرها وفقًا للمواصفات والمتطلبات التي حددها
                                العميل. "العقد" يشير إلى العقد الذي يتم توقيعه
                                خطيًا أو عن طريق الموقع الالكتروني الخاص
                                بالتطبيق من ناحية والعميل من ناحية أخرى، أو الذي
                                يتم إبرامه بشكل ضمني وفق الشروط والأحكام عن طريق
                                طلب الخدمة من جانب العميل وقيام التطبيق
                                بتنفيذها، وفي جميع الأحوال يكون العقد الصريح أو
                                الضمني ملزمًا للعميل.
                            </>,
                            `"AWASUL", "application", "we" or "our" refers to AWASUL application and Energize Information Technology Company LTD in the Kingdom of Saudi Arabia.
                                 “Agreement” refers to this document and what in includes in relation to the terms, conditions, and the privacy policy.
                                 “User", "you" or "your” refers to the person who uses AWASUL application, visits it, or visits the content available through the application.
                                 "Client" refers to individuals, institutions, companies, and public and private bodies that submit purchase requests for programs and products provided by the application in accordance with the terms and conditions of this agreement.
                                 “Software” or “Product” refers to the programs that the application displays for sale and which is provided in accordance with the specifications and requirements specified by the client.
                                 “Contract” refers to the contract that is signed in writing or through the website of the application on one hand and the client on the other, or which is implicitly concluded in accordance with the terms and conditions by requesting the service by the client and the application implemented it, and in all cases the explicit or implicit contract is binding on the client.`
                        )}
                    </Paragraph>

                    <Paragraph
                        title={__('وصف الخدمات', 'Description of services')}
                    >
                        <ul>
                            <li>
                                {__(
                                    <>
                                        يوفر تطبيق أواصل مجموعة متنوعة من
                                        الخدمات للعملاء الراغبين في شرائها
                                        (التواصل الداخلي، إدارة الموارد البشرية،
                                        إدارة المشاريع، التنبيهات والطوارئ،
                                        الخدمات المكتبية).
                                    </>,
                                    `AWASUL application provides a variety of services to clients who want to purchase them (internal communication, human resources management, project management, alerts and emergencies, office services).`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        يقدم التطبيق منصة التواصل الداخلي الأكثر
                                        تخصصًا للعمل عن بُعد، ويوفر التطبيق
                                        مجموعة متنوعة من الباقات التي تتناسب مع
                                        جميع عملائنا مع توفير خدمات الدفع
                                        الإلكتروني.
                                    </>,
                                    `The application provides an internal communication platform more specialized for remote work, and the application provides a variety of packages that suit all our clients while providing electronic payment services.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        يحصل العميل على شهر مجانًا من تاريخ
                                        الاشتراك في أي من الباقات الخاصة بنا.
                                    </>,
                                    `The client receives one month for free from the date of subscription to any of our packages.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        يوفر التطبيق الدعم الفني مجانًا خلال
                                        الثلاثة أشهر الأولى من تاريخ الاشتراك،
                                        ثم يلتزم العميل بالاشتراك في الدعم الفني
                                        بما يساوي 17% من قيمة العقد.
                                    </>,
                                    `The application provides the technical support for free during the first three months from the subscription date, and then the client is committed to subscribe to the technical support equal to 17% of the value of the contract.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__('الباقات والأسعار', 'Packages and prices')}
                    >
                        <dl>
                            {__(
                                <>
                                    يوفر التطبيق مجموعة متنوعة من الباقات
                                    والأسعار للبرامج والخدمات التي يوفرها
                                    التطبيق
                                </>,
                                `The application offers a variety of packages and prices for the programs and services offered by the application:`
                            )}
                            <dt>
                                {__(
                                    'باقة التواصل الداخلي (13 ريال شهريًا للفرد):',
                                    'nternal Communication Package (13 riyals per month per person):'
                                )}
                            </dt>
                            <dd>
                                {__(
                                    <>
                                        وهي مخصصة للشركات الناشئة والمجموعات
                                        الصغيرة للحفاظ على بيئة وظيفية مترابطة،
                                        وتحتوي الباقة على:
                                    </>,
                                    `It is dedicated for start-up companies and small groups to maintain an interrelated functional environment. The package contains:`
                                )}
                            </dd>
                            <ul>
                                <li>
                                    {__(
                                        'إنشاء موقع انترنت خاص بالمنشأة.',
                                        'Creating a website for the facility.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'صفحة شخصية لكل موظف.',
                                        'A profile for each employee.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'محادثات كتابية ومكالمات كتابية وصوتية ومرئية.',
                                        'Written conversations, voice and video calls.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'تبادل خبرات 24/7',
                                        'Exchange of experiences 24/7.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'خدمات شخصية (مواعيد تنبيه).',
                                        'Personal services (calendar).'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'سعة التخزين للمستخدم 2GB.',
                                        'User storage capacity 2GB.'
                                    )}
                                </li>
                            </ul>
                            <dt>
                                {__(
                                    'باقة الأعمال (16 ريال شهريًا للفرد):',
                                    'Business Package (16 riyals per month per person): '
                                )}
                            </dt>
                            <dd>
                                {__(
                                    `  وهي مخصصة للشركات التي تعمل كفريق واحد من أجل تحسين أدائها، وتحتوي
                                      الباقة على:`,
                                    'It is dedicated for companies that are working as a teamwork to improve their performance. The package contains:'
                                )}
                            </dd>
                            <ul>
                                <li className="bold">
                                    {__(
                                        'باقة التواصل الداخلي إضافة إلى:',
                                        'Internal communication package in addition to:'
                                    )}
                                </li>
                                <li>{__('أخبار المنشأة.', 'Company news.')}</li>
                                <li>
                                    {__(
                                        'الحملة الداخلية.',
                                        'Internal campaign.'
                                    )}
                                </li>
                                <li>{__('التعاميم.', 'Circulars')}</li>
                                <li>
                                    {__(
                                        'الترحيب بالموظف الجديد.',
                                        'New employee announcement'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'التدريب لعدد جلستين.',
                                        'Training for two sessions.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'سعة التخزين للمستخدم 4GB',
                                        'Storage capacity 4 GB. per-user'
                                    )}
                                </li>
                            </ul>
                            <dt>
                                {__(
                                    'باقة الأعمال المحترفة (27 ريال للفرد):',
                                    'Professional Business Package (17 riyal per person): '
                                )}
                            </dt>
                            <dd>
                                {__(
                                    <>
                                        وهي مخصصة لإدارة الأعمال الداخلية
                                        للشركات التي تعمل عن بُعد لتحسين الإنتاج
                                        لكسب رضاء العميل وزيادة الدخل، وتحتوي
                                        الباقة على:
                                    </>,
                                    'It is dedicated to managing the internal business of companies that work remotely to improve production to gain client satisfaction and increase income. The package includes:'
                                )}
                            </dd>
                            <ul>
                                <li className="bold">
                                    {__(
                                        'باقة الأعمال بالإضافة إلى:',
                                        'Business package in addition to:'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'لقاءات عبر الدائرة التلفزيونية المغلقة.',
                                        'CCTV interviews.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'دليل الإجراءات.',
                                        'The procedure manual.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'أرشفة الملفات والمستندات.',
                                        'Electronic signature.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'تحضير الموظفين.',
                                        'Archiving files and documents.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'تأكيد استلام تعميم / حملة اعلامية',
                                        'Employee attendance record'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'توقيع إلكتروني.',
                                        'Confirmation of receipt of circular / media campaign'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'التدريب لعدد 3 جلسات',
                                        'Training for 3 sessions.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'سعة التخزين للمستخدم 8GB.',
                                        'Storage capacity 8 GB. per-user'
                                    )}
                                </li>
                            </ul>
                            <dt>
                                {__(
                                    'باقة المؤسسات (تواصل معنا):',
                                    `The Institutions Package (contact us): 10 SR /person when the number of subscribers is (1000 - 1999).`
                                )}
                            </dt>
                            <span className="bold inline-block">
                                {__(
                                    '10 ريال للفرد عندما يكون عدد المشتركين (1000 – 1999).',
                                    '8 SR /person when the number of subscribers is (+ 2000).'
                                )}
                            </span>
                            <span className="bold inline-block">
                                {__(
                                    '8 ريال للفرد عندما يكون عدد المشتركين (+ 2000).',
                                    '10 SR /person when the number of subscribers is (1000 - 1999).'
                                )}
                            </span>
                            <dd>
                                {__(
                                    'وهي مخصصة للهيئات التي تسعى إلى إدارة العمل والمشروعات على المستوى المؤسسي، وتحتوي الباقة على:',
                                    'It is dedicated to Governmental and private organization that seek to manage work and projects at the institutional level. The package includes:'
                                )}
                            </dd>
                            <ul>
                                <li className="bold">
                                    {__(
                                        'باقة الأعمال المحترفة إضافة إلى:',
                                        'Professional Business Package in addition to:'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'إدارة المشاريع.',
                                        'Project management.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'التنبيهات والطوارئ',
                                        'Alerts and emergencies.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'التدريب لعدد خمسة جلسات',
                                        'Training for 5 sessions.'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'سعة التخزين للمستخدم 12GB.',
                                        'Storage capacity 12 GB. per-user.'
                                    )}
                                </li>
                            </ul>
                        </dl>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'سياسة الدفع والإلغاء',
                            'Payment & Cancellation Policy'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    'تكون العملة الرسمية المعتمدة من جانب التطبيق هي المعتمدة في تحديد أسعار الباقات وعمليات الشراء من خلال التطبيق.',
                                    `The official currency approved by the application is adopted in determining the prices of the packages and purchase processes through the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل الإطلاع على أسعار الباقات قبل تقديم طلب الشراء من خلال التطبيق.',
                                    'he client must check the prices of the packages before submitting the purchase order through the application.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يتحمل العميل مسئولية عملية الدفع كاملة، وبالتالي لا نتحمل أي أخطاء في عملية الدفع.',
                                    'The client bears the responsibility for the whole payment process, and therefore we do not bear any errors in the payment process.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل أن يحافظ على سرية بيانات الدفع الخاصة به والحرص على تجنب استخدام أو وسائل مشبوهة أو برمجيات غير مرخصة.',
                                    'The client must maintain the confidentiality of his payment data, and he must be careful to avoid using suspicious methods or unlicensed software.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يتيح التطبيق للعميل طلب إلغاء التعاقد خلال 7 أيام من تاريخ الدفع، وفي هذه الحالة سيقوم التطبيق برد الأموال المدفوعة من العميل خلال 14 يوم بنفس طريقة الدفع، مع تحمل العميل كافة الرسوم البنكية بالإضافة إلى أي رسوم إدارية يطبقها أواصل وخصم قيمة مدة الاشتراك حتى لو كانت في فترة العرض المجاني.',
                                    'The application allows the client to request the cancellation of the contract within 7 days from the date of payment, in this case the application will refund the money paid by the client within 14 days with the same payment method, while the client bearing all bank fees in addition to any administrative fees applied by Awasul application and deducting the value of the subscription period even if it is in the free offer period.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'شروط عضوية العميل',
                            'Conditions of Client Membership'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    'يستطيع المستخدم تسجيل حسابه بالتطبيق الذي يتيح له العديد من المزايا والخدمات المختلفة.',
                                    'he client can register his account on the application that allows him to have many different features and services.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'لتسجيل العضوية بالتطبيق يجب على المستخدم تزويدنا بالبيانات الآتية (الاسم الأول، الاسم الأخير، البريد الالكتروني، اسم الدولة التي سوف يستخدم فيها التطبيق ، رقم الجوال).',
                                    'To register the membership on the application, the user must provide us with the following data (the first name, the last name, the email, the country_id where the application will be use , and mobile number).'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل تسجيل العضوية بتطبيق أواصل برقم جوال وبريد الكتروني تابعين له، حتى يتمكن من استلام أي إشعارات مرسلة له من تطبيق أواصل.',
                                    `The client must register for membership on AWASUL application with his mobile number and email, so that he can receive any notifications sent to him from AWASUL application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يحظر على أي عميل التسجيل لدينا بأكثر من حساب وسنقوم بحذف جميع الحسابات في حال اكتشافنا ذلك.',
                                    `Any client is forbidden to register on our application with more than one account and we will delete all accounts if we found that out.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل الحفاظ على اسم المستخدم وكلمة المرور الخاصة به، وألا يقوم بالإفصاح عنهم للغير، وفي جميع الأحوال يلتزم العميل بكافة التعاملات التي تتم من خلال حسابه بالتطبيق.',
                                    'The client must maintain his username and password, and not disclose them to others, and in all cases the client is committed to all the transactions made through his account on the application.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'تقدم العضوية بالتطبيق بنظام الترخيص بالاستخدام، وبالتالي يحق لنا سحب هذا الترخيص وإنهاء استخدام العضوية في أي وقت من الأوقات ودون إبداء أي أسباب.',
                                    `The membership on the application is provided with a licensing system for using the membership, so we have the right to revoke this License and terminate the membership at any time without providing any reasons.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__('شروط الاستخدام', 'Conditions of Use')}
                    >
                        <ul>
                            <li>
                                {__(
                                    'يتم توفير الخدمات من خلال التطبيق وفق القوانين المعمول بها في المملكة العربية السعودية، وبالتالي فأنت تلتزم بهذه القوانين بغض النظر عن الدولة التي تنتمي إليها.',
                                    `Services are provided through the application in accordance with the laws applicable in the Kingdom of Saudi Arabia, therefore you comply with these laws regardless of the country_id to which you belong.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب أن يكون المستخدم مؤهل قانونًا بالدخول معنا في هذه الاتفاقية، وأن يكون بلغ من العمر 18 عامًا فأكثر وقت استخدام تطبيقنا.',
                                    `The user must be legally qualified to enter into this Agreement with us and he must be 18 years and older at the time of using our application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب إدخال أي معلومات مطلوبة بالتطبيق بشكل صحيح وتكون مسئول عنها وعن دقتها وجودتها وحداثتها وقانونيتها، وتلتزم بتحديثها كلما حدث أي تغيير لها.',
                                    `Any information required must be entered on the application correctly and you must be responsible for it, its recency, quality, and legality, and you are obligated to update it whenever any change occurs.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب أن يتم استخدام التطبيق بشكل قانوني ووفق الأهداف والأغراض المعلن عنها من جانبنا، وأن  يتسم هذا الاستخدام بالجدية وأن يبتعد المستخدمين عن استخدام التطبيق لأغراض التدليس أو الاحتيال أو التواصل غير المشروع أو التواصل الوهمي أو الإضرار بأي طرف من الأطراف.',
                                    `The application must be used legally and in accordance with the objectives and purposes declared by our part , and that this use should be serious and the users shouldn’t use the application for the purposes of fraud, scam, illegal communication, fake communication or harming to any party.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على المستخدم إخطارنا في حالة اكتشاف أي ممارسات أو أنشطة غير قانونية من خلال التطبيق.',
                                    `The user must notify us in case of finding out any illegal practices or activities through the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب أن يحافظ المستخدم على سمعة التطبيق وألا يسيء للتطبيق بشكل مباشر أو غير مباشر، وألا يتسبب لنا في أضرار مباشرة أو غير مباشرة، وألا يتسبب لنا في أي مطالبات قانونية.',
                                    `The user must maintain the reputation of the application, not to offend the application directly or indirectly, does not cause us direct or indirect damages, and does not cause us any legal claims.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب أن تكون التقييمات والتعليقات التي يقدمها العميل من خلال التطبيق صادقة وقانونية ولا تتضمن أي تعدي على التطبيق أو التجار أو الجهات الأخرى.',
                                    `The reviews and comments provided by the client through the application must be honest and legal and do not include any amendment to the application, merchants, or other entities.`
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب أن يكون المحتوى الذي يقدمه المستخدم من خلال التطبيق قانوني ولا يتضمن أي اعتداء على الحقوق الخاصة بالآخرين.',
                                    `The content provided by the user through the application must be legal and does not include any assault on the rights of others.`
                                )}
                            </li>
                            <li>
                                {__(
                                    `يحتفظ تطبيق  "أواصل" لنفسه بالحق بأن يجري أية تعديلات أو تغييرات على تطبيقه وعلى السياسات والاتفاقيات المرتبطة بتطبيق "أواصل" بما في ذلك الشروط والأحكام.`,
                                    `“AWASUL” application reserves the right to make any modifications or changes to its application and to policies and agreements associated with “AWASUL” application including terms and conditions.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__('حدود الاستخدام', 'Limitations of The Use')}
                    >
                        <ul>
                            <li>
                                {__(
                                    'يجب استخدام التطبيق في حدود الأغراض المعلنة من خلال هذه الشروط أو تلك المعلنة عبر صفحات التطبيق، وأن يتم استخدام خدماتنا بحسن نية وعدم التلاعب على التطبيق أو المتعاملين من خلاله بأي صورة من الصور.',
                                    'The application must be used within the purposes declared through these terms or those declared through the pages of the application, and that our services should be used in good faith and not to manipulate the application or its clients in any way.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب استخدام التطبيق بشكل قانوني ومشروع ويحظر مباشرة أي أعمال من شأنها تعريض التطبيق للمساءلة القانونية.',
                                    'The application must be used legitimately and legally, and that exercising any actions that expose the application to the legal accountability must be forbidden.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يحظر إعادة بيع أي جزء من أجزاء التطبيق أو استغلاله بشكل تجاري والتربح من وراء ذلك باستثناء الأنشطة المصرح بها من جانبنا.',
                                    't is forbidden to resell or commercially exploit any part of the application, or profiteering from this, except for activities permitted by us.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يحتفظ التطبيق بكافة حقوقه القانونية في حالة إخلال أي من مستخدمي التطبيق بحقوقنا القانونية والمشروعة أو بحقوقنا المنصوص عليها في هذه الاتفاقية.',
                                    'The application reserves all its legal rights in the case that any user of the application violates our legal, legitimate rights or our rights enshrined in this agreement.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `النصوص والرسومات والصور الفوتوغرافية والشعارات والرسوم التوضيحية والشروحات والبيانات والمواد الأخرى المقدمة منا على أو من خلال خدمات تطبيق "أواصل" بالإضافة إلى اختيارهم وتجميعهم وترتيبهم من المحتمل أن يحتوي على أخطاء أو إغفال أو أخطاء مطبعية أو أن يكون قديماً، ويجوز للتطبيق أن يغير أو يحذف أو يُحدث أي محتوى في أي وقت بدون إخطار مسبق. `,
                                    'Texts, drawings, photographs, logos, illustrations, explanations, data and other materials provided by us on or through the services of “AWASUL” application in addition to its selection, compilation and arrangement that may contain errors, omissions, typographical errors or to be outdated, and the application may change, delete or update any content at any time without prior notice.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يُقدم المحتوى من خلال تطبيق أواصل لأغراض المعلومات فقط، ولأغراض تقديم خدماتنا وبشكل حصري، ولا يجوز تخصيصه لأي محتوى أو مواقع أو جهات غير تابعة لنا.`,
                                    'The content is provided through AWASUL application for information purposes only, and for the purposes of providing our services exclusively, and it should not be dedicated for any content, applications or bodies that are not ours.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph title={__('الترخيص', 'License')}>
                        <ul>
                            <li>
                                {__(
                                    'يجب أن يكون العميل مؤهل قانونًا لتقديم طلب شراء الباقات من خلال التطبيق.',
                                    'The client must be legally qualified to submit the purchase order of the packages through the merchant.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل الإطلاع على مواصفات الباقات المعلن عنها عبر التطبيق قبل تقديم طلب الشراء.',
                                    'The client must access on the specifications of the packages advertised through the application before submitting the purchase order.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل إتباع كافة الأحكام المنصوص عليها في هذه الاتفاقية، كما يلتزم بالقوانين السارية المفعول.',
                                    'The client must follow all the provisions that are stipulated in this agreement and he must comply with the applicable laws.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يقدم العميل طلب شراء الباقات على مسئوليته الشخصية دون أن يقدم التطبيق أي ضمانات أو تعهدات إضافية سوى المنصوص عليها في هذه الوثيقة.',
                                    'The client submits the purchase order of the packages at his own responsibility without the application provide any additional guarantees or pledges except these stipulated in this document.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يحق للتطبيق حظر عضوية العميل لدينا إذا اكتشفنا في أي وقت مخالفة العميل لأي من الشروط والأحكام المنصوص عليها في هذه الوثيقة أو قيام العميل بتقديم طلبات شراء وهمية أو الإساءة لنا بأي شكل من الأشكال.`,
                                    `The application has the right to block the membership of our The application has the right to block the membership of our client if we found out at any time that the client has violated any of the terms and conditions stipulated in this document or that the client has made fake purchase orders or abused us in any way.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph title={__('التثبيت', 'Installation')}>
                        {__(
                            <>
                                يحق لك استخدام البرنامج وتثبيته على جهاز الحاسب
                                الآلي الخاص بك، بعد التعاقد معنا ودفع مقابل
                                الترخيص، ويتم التثبيت إما من خلال شركة تنشيط
                                لتقنية المعلومات أو بإتباع التعليمات التي تقدمها
                                الشركة لك، ويجب عليك استخدام مضاد للفيروسات
                                للحفاظ على سلامة البرنامج وأن يكون جهازك خالي من
                                أي مكونات أو برمجيات ضارة.
                            </>,
                            `You shall be entitled to use the software and to install it on your computer after having contracted with us and having paid the license fee. Installation is done either by Energize Information Technology Company or through following the instructions submitted by the company to you and you must use an anti-virus to keep safe the software and your device must be free from any harmful components or software.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('شروط العميل', "Client's Conditions")}>
                        <ul>
                            <li>
                                {__(
                                    `نظام الترخيص: أنت تعلم وتوافق على أن استخدامك للبرنامج بنظام "الترخيص" وليس بنظام البيع، فيظل البرنامج مملوك لشركة تنشيط لتقنية المعلومات ، وهي الوحيدة التي تملك حق بيعه أو الترخيص للغير باستخدامه.`,
                                    'License Regime: You know and agree that your use of the software is with the license regime and not with the sale regime. Thus, the software shall remain to be owned by Energize Information Technology Company that is solely entitled to sell or license it to be used by third parties.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'أغراض الاستخدام: يشمل الترخيص استخدام البرنامج للأغراض المصمم من أجلها فقط.',
                                    'Purposes of Use: They shall include the license to use the software for the purposes for which it was designed only.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `عدد التراخيص: يقتصر الترخيص بالاستخدام على كافة الأجهزة المرخص لها والتابعة للمنشأة "العميل"، ما لم يوجد اتفاق كتابي بين الطرفين يحدد عدد الأجهزة المرخص لها باستخدام البرنامج.`,
                                    'Number of Licenses: License to use shall be limited to all licensed devices belonging to the client unless there is a written agreement between the two parties that determines the number of devices licensed to use the software.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'مدة الترخيص: يحق لك استخدام البرنامج لمدة محددة (لمدة محددة).',
                                    'License Term: You shall be entitled to use the software for a specified term (Package term).'
                                )}
                            </li>
                            <li>{__(`النسخة الاحتياطية:`, 'Spare Copy:')}</li>
                            <dd>
                                {__(
                                    `يحق لك عمل نسخة احتياطية واحدة من البرنامج على وسيط تخزين لاستخدامها في حالة تلف النسخة المثبتة، ويجب أن تكون هذه النسخة الاحتياطية في حوزة العميل وحده.`,
                                    'You shall be entitled to make one spare copy of the software on a storage medium to be used in case of the damage of the installed copy and the spare copy must be possessed by the client only.'
                                )}
                            </dd>
                            <dd>
                                {__(
                                    `في حال تلف البرنامج بسبب أخطاء غير مباشرة أو غير متعمدة يقوم التطبيق بتثبيت البرنامج مرة أخرى للعميل بشكل مجاني.`,
                                    'If the software is damaged due to indirect or unintentional errors, the application installs the software again for the client free of charge.'
                                )}
                            </dd>
                            <dd>
                                {__(
                                    `في حال تلف البرنامج بسبب أخطاء مباشرة أو متعمدة يقوم التطبيق بتثبيت البرنامج مرة أخرى للعميل مقابل تكلفة تثبيت البرنامج التي يحددها من جانبنا.`,
                                    'f the software is damaged due to direct or intentional errors by the client or his users, the application will reinstall the software for a fee that we specify.'
                                )}
                            </dd>
                            <li>
                                {__(
                                    'إنهاء الترخيص: تنتهي التراخيص بالمدة المحددة لها، أو بعدم التزام المستخدم بهذه الاتفاقية، وذلك دون الإخلال بأي حقوق لنا أو تعويضات ناشئة عن ذلك الإخلال.',
                                    'Termination of License: Licenses shall be terminated after the lapse of their specified terms or after the user being bound by this agreement without breaching our rights or compensations arising from this breach.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'نقل التراخيص: إذا رغب المستخدم في نقل التراخيص الممنوحة له لشخص آخر فيجب أن يتم ذلك من خلال الشركة وأن توافق كتابة على نقل التراخيص، وفي هذه الحالة يحظر على المستخدم استخدام البرنامج من وقت نقل الترخيص.',
                                    'Transfer of Licenses: In case the user wishes to transfer the licenses granted to him to someone else, this shall be done through the company with its written consent to the transfer of the licenses. In this case, the user shall be prohibited from using the software from the time of transferring the license.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'ترخيص غير حصري: يعلم المستخدم ويوافق على أن الترخيص المقدم له من الشركة غير حصري، وبالتالي يحق لشركة تنشيط لتقنية المعلومات إعادة الترخيص للغير باستخدام البرنامج وفق الشروط التي تحددها.',
                                    'Non-exclusive license: The user knows and agrees that the license provided to him by the company is non-exclusive. As a result, Awasul Company shall be entitled to relicense the use of the software to third parties in accordance with the conditions it determines.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph title={__('التحديثات', 'Updates')}>
                        {__(
                            <>
                                لا يحق للعميل عمل تحديثات للبرنامج أو تعديله أو
                                تطويره بأي شكل من الأشكال، ويكون من حق شركة
                                تنشيط لتقنية المعلومات وحدها عمل التحديثات
                                والتطويرات للبرنامج، ولا يشمل سعر البرنامج
                                الحالي إجراء التحديثات، وفي حال طلب التحديثات من
                                جانب العميل فإنه يلتزم بدفع مقابل جديد مقابل
                                تزويده بالتحديثات المطلوبة.
                            </>,
                            'The client shall not be entitled to make updates of the software, its modification or development by any means and Energize Information Technology Company only shall be entitled to make updates and the developments of the software and the current price of the software doesn’t include updates. In the case that updates are requested by the client, he shall pay a new fee for providing him with the required updates.'
                        )}
                    </Paragraph>

                    <Paragraph title={__('سياسة المحتوى', 'Content Policy')}>
                        <ul>
                            <li>
                                {__(
                                    'يوفر التطبيق المحتوى من خلاله لأغراض تقديم وعرض خدماتنا على المستخدمين وتسويق الباقات من خلال التطبيق.',
                                    'The application provides the content through it for the purposes of providing and displaying our services to the users and marketing packages through the application.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'قد تتضمن البيانات المتاحة من خلال التطبيق بعض الأخطاء غير المتعمدة، وبالتالي فأنت تعفينا من أي مسئولية ناشئة عن ذلك، كما أنك تتعهد بالتواصل معنا للاستفسار وطلب تصحيح أي أخطاء موجودة في البيانات.',
                                    'The data available through the application may include some unintentional errors, so you exempt us from any liability arising from this. Also, you undertake to contact us to inquire and request to correct any errors in the data.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يكون التطبيق ملزم بتسليم البرنامج في حدود البيانات المتاحة للباقات.',
                                    'The application is obliged to deliver the software within the data available to the packages.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يجب على العميل التحقق من أي محتوى متاح من خلال التطبيق، والتحقق من مدى صحته ودقته وأن يبذل عناية الشخص الحريص في التعامل مع المحتوى.',
                                    'The client must check any content available through the application, check its authenticity and accuracy and to conduct due diligence like the person who is keen to handle the content.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `لا يتحمل تطبيق أواصل أي مسئولية قانونية ناشئة عن المحتوى المتاح من خلال التطبيق، وأنت تعفينا عن ذلك صراحة.`,
                                    'All the data available to other people through the application is provided at their own responsibility without any guarantees from the application'
                                )}
                            </li>
                            <li>
                                {__(
                                    `كافة البيانات المتوفرة لأشخاص آخرين عبر التطبيق يتم توفيرها على مسئوليتهم الشخصية دون تقديم أي ضمانات من جانب التطبيق.`,
                                    `AWASUL application does not bear any legal liability arising from the content available through the application, and you explicitly exempt us from doing so.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph title={__('الدعم الفني', 'Technical Support')}>
                        <ul>
                            <li>
                                {__(
                                    'في حالة طلب الدعم الفني من جانب المستخدم، يجب عليه التواصل مع شركة تنشيط لتقنية المعلومات لتحديد ميعاد تقديم الدعم وتكلفته.',
                                    'n case of asking for technical support by the user, he shall contact Energize Information Technology Company to fix and appointment of providing the technical support and its cost.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يوفر التطبيق الدعم الفني مجانًا خلال الثلاثة أشهر الأولى من تاريخ الاشتراك، ثم يلتزم العميل بالاشتراك في الدعم الفني بما يساوي 17% من قيمة العقد.',
                                    'he application provides the technical support for free during the first three months from the subscription date, and then the client is committed to subscribe to the technical support equal to 17% of the value of the contract.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'في حالة قيام المستخدم بالدعم الفني بنفسه أو الاستعانة بالغير لتقديمه (لا تتحمل شركة تنشيط لتقنية المعلومات أي نتائج ناشئة عن الدعم الفني من غير المصرح لهم من جانب شركة تنشيط لتقنية المعلومات) فيجب ألا يؤثر ذلك على أحكام هذه الاتفاقية، فيحظر التلاعب في البرنامج أو شيفرة المصدر الخاصة به.',
                                    'n case the user does the technical support by himself or gets assisted by others to provide this technical support (Energize Information Technology Company does not bear any results arising from technical support from those who are not authorized by Energize Information Technology Company), this mustn’t affect the provisions of this agreement and manipulation of this software or the code of its source shall be prohibited.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__('إخلاء المسئولية', 'Discharge of Liability')}
                    >
                        <ul>
                            <li>
                                {__(
                                    `يتم توفير البرنامج "كما هو دون أي ضمان من أي نوع"، ولا تضمن الشركة أو تتحمل المسئولية عن دقة أو اكتمال المعلومات أو النصوص أو الرسومات أو العناصر المضمنة في البرنامج، حيث يتم تصميم أو تجهيز البرنامج بناء على طلب العميل.`,
                                    'The software shall be made available as it is without any warranty whatsoever and the company shall not warrant or be liable for the precision or completion of the information, texts, drawings or elements included in the software where the design or preparation of the software is done at the request of the client.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'أنت توافق على إخلاء مسئولية شركة تنشيط لتقنية المعلومات وموظفيها والأشخاص التابعين لها عن استخدام البرنامج أو عدم قدرة المستخدم على استخدامه بالشكل الأمثل أو عن أي توقف أو أعطال أو فيروسات تصيب البرنامج نتيجة ضعف الحماية وعن أي خسارة في الأرباح أو العائدات أو المبيعات أو البيانات أو تكاليف أو توقف نشاط تجاري أو فقدان أي معلومات متعلقة بالنشاط التجاري أو أي أضرار مباشرة أو غير مباشرة أيًا كان سببها.',
                                    'You agree upon discharging the liability of Energize Information Technology Company, its employees and the persons belonging to it for the use of the software or the inability of the user to use it in the best way, for any interruption, break downs or viruses caused to the software as a result of the weak protection or for any loss of profits, returns, sales, data, costs, the suspension of a business activity or the loss of any information related to the business activity, for any direct or indirect harms whatsoever their reasons are.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'يطبق بند إخلاء المسئولية لأقصى مدى يسمح به القانون.',
                                    'Clause of discharge of liability shall apply to the maximum extent permitted by law.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph title={__('الأطراف الثالثة', 'Third Parties')}>
                        <ul>
                            <li>
                                {__(
                                    `قد يساعدنا في تقديم خدماتنا أطراف ثالثة، ويخلي التطبيق مسئوليته القانونية عن أي أخطاء مباشرة أو غير مباشرة، متعمدة أو غير متعمدة، تقع من الأطراف الثالثة التي تقوم بتقديم الخدمات عبر التطبيق.`,
                                    'he third parties may assist us in providing our services, and the application release its legal responsibility for any direct or indirect, intentional, or unintentional errors made by the third parties which provide services through the application.'
                                )}
                            </li>
                            <li>
                                {__(
                                    'قد يطبق على المستخدم شروط وأحكام خاصة بالأطراف الثالثة وهذه لا تخضع لسيطرتنا وبالتالي يجب على المستخدم الإطلاع على هذه السياسات والموافقة عليها قبل الاستفادة من الخدمات التي يقدمها الأطراف الثالثة من خلالنا. ',
                                    'Terms and conditions of third parties may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from  the services provided by the third parties through us.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'حقوق الملكية الفكرية',
                            'Intellectual Property Rights'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    `التطبيق والبرنامج وجميع عناصره المادية والمعنوية مملوك ملكية خاصة لنا، ولا يجوز تقليده أو نسخه أو إعادة استغلاله بأي شكل من الأشكال. وجميع المحتويات الخاصة بالتطبيق من (محتوى، قوائم، نصوص، صور، فيديو، رموز، أرقام، حروف، أيقونات، أزرار، موسيقى، بيانات، معلومات) خاضعة للحماية القانونية بموجب القوانين المعمول بها في المملكة العربية السعودية والاتفاقيات الدولية، ويحق لنا اتخاذ الإجراءات القانونية في حالة الاعتداء عليها.`,
                                    'The application and software and all its material and moral elements are privately owned by us, and it should not be imitated, copied or re-used in any way. All the contents of the application (content, lists, texts, images, video, symbols, numbers, letters, icons, buttons, music, data, information) is subject to legal protection under the laws of the Kingdom of Saudi Arabia and international conventions, and we have the right to take the legal actions in case of  attacks on it.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `"أواصل AWASUL" هي علامة تجارية ومستخدمة من جانبنا ولا يجوز التعدي عليها أو تقليدها أو نسخها أو تداولها بشكل غير قانوني أو استخدامها على علامات أو خدمات غير تابعة لنا، وفي حالة الاعتداء على تلك العلامة يحق لنا اتخاذ كافة الإجراءات القانونية التي تحفظ كافة حقوقنا التجارية عليها.`,
                                    '"AWASUL" is a trademark used by us and it should not be infringed, imitated, copied, traded illegally or used on marks or services that are not ours, and in case of an attack on that trademark, we have the right to take all the legal procedures that preserves all our commercial rights.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__('المسئولية القانونية', 'Legal Liability')}
                    >
                        <ul>
                            <li>
                                {__(
                                    `كل مستخدم مسئول مسئولية شخصية عن كافة الأفعال والأنشطة التي يقوم بها من خلال التطبيق، ولن يكون التطبيق مسئول بالتضامن أو بالتبعية عن أي من المستخدمين.`,
                                    'Each user is personally responsible for all the actions and activities which he carries out through the application, and the application will not be subsidiary or solidarity responsible of any users.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `كل مستخدم مسئول مسئولية شخصية في حالة عدم التزامه بالالتزامات المفروضة عليه بموجب هذه الشروط أو السياسات المطبقة.`,
                                    'Each user is personally responsible in case he does not comply with the commitments imposed on him under these applicable terms or policies.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `كل مستخدم مسئول مسئولية شخصية في حالة إخلاله بالقوانين المعمول بها في المملكة العربية السعودية أو في الدولة التي ينتمي إليها، ولن يكون التطبيق مسئول بالتضامن أو بالتبعية عن أي من المستخدمين.`,
                                    'Each user is personally responsible in case he violates the laws applicable in the Kingdom of Saudi Arabia or the country_id to which he belongs, and the application will not be subsidiary or solidarity responsible of any users.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يتحمل المستخدم المسئولية القانونية في حالة إخلاله بأي حق من حقوقنا بموجب هذه الشروط والأحكام أو الاعتداء على أي حق من حقوقنا أو ملكيتنا للتطبيق أو أي من عناصره.`,
                                    'The user shall bear the legal responsibility in case that he violates any of our rights under these terms and conditions, attacks on any of our rights, our ownership of the application or any of its elements.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يتحمل العميل كافة المسئوليات القانونية والتعويضات في حالة تقديم طلبات شراء وهمية أو إساءة استخدام خدمات التطبيق بأي شكل من الأشكال.`,
                                    'The client shall bear all the legal responsibilities and compensations in case of providing fake purchase orders or misusing the services of the application in any way.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يتحمل العميل المسئولية القانونية في حالة إساءة استخدام البرامج التي يحصل عليها من خلال التطبيق.`,
                                    'The user shall bear the legal responsibility in case of offending the application in any website, application, social media, email or through any other means.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يتحمل المستخدم المسئولية القانونية في حالة الإساءة للتطبيق في أي موقع أو تطبيق أو وسائل تواصل اجتماعي أو عبر البريد الالكتروني أو من خلال أي وسيلة أخرى.`,
                                    'The user shall bear the legal responsibility in case of offending of any other person through the application, without original, subsidiary, or joint responsibility on the application.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يتحمل المستخدم المسئولية القانونية في حالة الإساءة لأي شخص آخر من خلال التطبيق، دون أدنى مسئولية أصلية أو تبعية أو تضامنية على التطبيق.`,
                                    'The user is obliged to compensate us for all the damages to the application as a result of any illegitimate or unauthorized use by the user or any of his followers.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يلتزم المستخدم بتعويضنا عن كافة الأضرار التي تصيب التطبيق نتيجة أي استخدام غير مشروع أو غير من مفوض من جانب المستخدم أو أي من تابعيه.`,
                                    'The user is obliged to compensate us in case of offending us, the administration of the application, employees, partners, distributors, agents, our affiliates or any other person who belongs to Awasul application.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يلتزم المستخدم بتعويضنا في حالة الإساءة لنا أو لإدارة التطبيق أو الموظفين أو الشركاء أو الموزعين أو الوكلاء أو التابعين لنا أو أي شخص آخر ينتمي إلى تطبيق أواصل.`,
                                    'We reserve all our legal rights arising from this Agreement at any time, and that not using the right at a specific time does not mean to waive it at all other times.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `نحتفظ بكافة حقوقنا القانونية الناشئة عن هذه الاتفاقية في أي وقت من الأوقات، ولا يعني عدم استخدام لذلك الحق في وقت معين التنازل عنه في جميع الأوقات الأخرى.`,
                                    'The user release us from the responsibility for all the illegitimate activities which are not our  and may occur through the application, the application cannot control all the actions carried through it, and the affected must inform us to take the necessary actions towards stopping the source of the damage.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `يخلي المستخدم مسئوليتنا عن كافة الأنشطة غير المشروعة غير التابعة لنا التي قد تحدث عبر التطبيق، فالتطبيق لا يستطيع السيطرة على كافة الأفعال التي تتم من خلاله ويجب على المتضرر إبلاغنا لاتخاذ اللازم نحو وقف مصدر الضرر.`,
                                    'The application may discontinues from time to time and it may permanently discontinues, whether for voluntary or involuntary reasons, and therefore you exempt us from any legal responsibility in case of a permanent or temporary discontinuing  of the application or any of its services.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `التطبيق قد يتوقف من وقت لآخر وقد يتوقف بشكل دائم سواء لأسباب إرادية أو غير إرادية وبالتالي فأنت تعفينا من أي مسئولية قانونية في حالة التوقف الدائم أو المؤقت للتطبيق أو أي من خدماته.`,
                                    'he application does not provide any type of insurance or compensations to any of its users or clients , and anyone who uses the application and its services at its own responsibility , and the application will not be  responsible to face any of the  users for any reason resulting from the use of the application or its services or apply our terms and policies.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `التطبيق لا يقدم أي نوع من أنواع التأمين أو التعويضات لأي من مستخدميه أو العملاء، وكل شخص يستخدم التطبيق وخدماته على مسئوليته الشخصية، ولن يكون التطبيق مسئول في مواجهة أي من المستخدمين لأي سبب ناتج عن استخدام التطبيق أو خدماته أو تطبيق شروطنا وسياساتنا.`,
                                    'The application is not responsible for any comments or content posted through it, or for any communications within or outside the application. '
                                )}
                            </li>
                            <li>
                                {__(
                                    `التطبيق غير مسئول عن أي تعليقات أو محتوى منشور من خلاله، أو عن أي عمليات تواصل داخل التطبيق أو خارجه.`,
                                    'In case the application is hacked or hacking, the user will exempt us from any legal liability arising from the loss of data, accounts or other damages to the user as a result of these illegitimate acts.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `في حالة تعرض التطبيق لعمليات اختراق أو قرصنة فإن المستخدم يعفينا من أي مسئولية قانونية ناشئة عن فقد البيانات أو الحسابات أو أي أضرار أخرى تصيب المستخدم نتيجة هذه الأعمال غير المشروعة.`,
                                    'The client shall bear the legal responsibility in case of misusing the software which he obtains through the application.'
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'الاتصالات والإشعارات',
                            'Communications and Notifications'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    `يقوم التطبيق بالتواصل معك من وقت لآخر من خلال بيانات الاتصال التي قدمتها لنا، وبموجب هذه الاتفاقية فأنت تفوضنا في التواصل معك إلكترونيًا أو هاتفيًا.`,
                                    'The application communicates with you from time to time through the contact details you have provided to us, and under this agreement you authorize us to communicate with you online or by telephone.'
                                )}
                            </li>
                            <li>
                                {__(
                                    `أي إشعارات يريد التطبيق إبلاغها للمستخدمين تتم من خلال بيانات الاتصال الخاصة بهم، ويفترض علم المستخدم بالإشعار بمجرد قيام التطبيق بإرساله له. وفي حالة رغبة المستخدم في إرسال الإشعارات لنا يجب أن يتم ذلك من خلال وسائل الاتصال الخاصة بنا المتاحة عبر صفحات التطبيق.`,
                                    `Any notifications the application wants to notify the users with are made through their own contact details, and the user is supposed to know about with the notification once the application sends it to him. In case that the user wants to send us notifications, this must be done through our means of communication available through the application's pages.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'التعديلات والإضافات',
                            'Amendments and Additions'
                        )}
                    >
                        {__(
                            <>
                                أنت تعلم وتوافق على أن خدماتنا قد يتم تعديلها أو
                                تحديثها بشكل مستمر، كما أن شروطنا وأحكامنا
                                وسياسة الخصوصية قد يتم تعديلها أو تحديثها أو
                                الإضافة عليها من وقت لآخر، ولن يكون التطبيق ملزم
                                بإخطار أي من مستخدميه، لذلك يجب عليك مراجعة هذه
                                الاتفاقية قبل أي عملية تقوم بها من خلال التطبيق،
                                وبناءً على ذلك أنت تقر بحق تطبيق "أواصل" في أي
                                وقت وبدون إخطار مسبق وبناء على تقديره وحده دون
                                غيره مراجعة هذه الشروط والأحكام أو فرض شروط
                                وأحكام جديدة متعقلة بخدمات تطبيق "أواصل" أو
                                الحصول عليها وتتحمل أنت مسؤولية مراجعة هذه
                                الشروط والأحكام بشكل دوري لمراجعة أي تعديل على
                                تلك الشروط والأحكام، ويشكل أي استخدام أو حصول لك
                                على منتجات أو خدمات تطبيق "أواصل" موافقتكم على
                                تلك المراجعات أو الإضافات.
                            </>,
                            `You know and agree that our services may be continuously amended or updated, and our terms, conditions and privacy policy may be amended, updated or added from time to time, and the application will not be obliged to notify any of its users, so you must review this agreement before any process you make through the application, and accordingly you acknowledge the right of AWASUL application at any time and without prior notice and at its sole discretion to review these terms and conditions or impose new terms and conditions related to AWASUL application You bear the responsibility for periodically reviewing these terms and conditions to review any amendment on these terms and conditions, and any use or obtain of the products or services of AWASUL application constitutes your consent to these reviews or additions.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('الإلغاء', 'Cancellation')}>
                        {__(
                            <>
                                يحق للتطبيق إلغاء أي من الخدمات المتاحة من خلاله
                                أو تعديل التطبيق بشكل كامل أو تغييره أو تغيير
                                نشاطه. كما يحق لنا إلغاء الشروط والأحكام وسياسة
                                الخصوصية أو استبدالهم في أي وقت دون أن يتطلب ذلك
                                الحصول على موافقتك.
                            </>,
                            `The application has the right to cancel any of the services available through it, adjust the application completely, change it or change its activity. We also have the right to cancel the terms, conditions and privacy policy or replace them at any time without requiring your consent.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('القانون', 'Law')}>
                        {__(
                            <>
                                يخضع تفسير وتنفيذ بنود هذه الوثيقة للأنظمة
                                المعمول بها في المملكة العربية السعودية، ولا
                                تقتصر هذه الشروط على البنود الواردة فيها وإنما
                                تمتد لتشمل كافة النصوص القانونية المنظمة
                                للعلاقات المدنية والتجارية المعمول بها في
                                المملكة العربية السعودية طالما كانت قواعد مكملة
                                ولا تتعارض بشكل مباشر أو غير مباشر مع البنود
                                الواردة في هذه الوثيقة
                            </>,
                            `The interpretation and implementation of the  items of this document is subject to the regulations applicable in the Kingdom of Saudi Arabia, and these terms are not limited to the items contained in it , but extend to include all the legal provisions regulating civil and commercial relations applicable in the Kingdom of Saudi Arabia so far as they were a complementary rules and do not directly or indirectly conflict with the items of this document.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('الاختصاص القضائي', 'Jurisdiction')}>
                        {__(
                            <>
                                يختص القضاء السعودي بالفصل في أي نزاع ينشأ بشأن
                                تفسير أو تنفيذ أي بند من بنود هذه الوثيقة، وفي
                                حالة استبعاد أي بند بموجب حكم قضائي فإن ذلك لا
                                يخل بصلاحية البنود الأخرى وتظل سارية ومنتجة
                                لآثارها القانونية ما لم يقم التطبيق بإلغاء
                                الاتفاقية.
                            </>,
                            `The interpretation and implementation of the  items of this document is subject to the regulations applicable in the Kingdom of Saudi Arabia, and these terms are not limited to the items contained in it , but extend to include all the legal provisions regulating civil and commercial relations applicable in the Kingdom of Saudi Arabia so far as they were a complementary rules and do not directly or indirectly conflict with the items of this document.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('اللغة العربية', 'Arabic Language')}>
                        {__(
                            <>
                                تم صياغة هذه الوثيقة باللغة العربية، وفي حالة
                                ترجمتها لأي لغة أخرى فإن النص العربي هو المعمول
                                به أمام كافة الجهات الرسمية وغير الرسمية إذا
                                تعارضت الترجمة الأجنبية معه.
                            </>,
                            `The Saudi judiciary shall adjudicate disputes arising about the interpretation or implementation of any item of this document, and in case that any item is excluded by a judicial decision, this is without prejudice to the competence of other items and that the items remain valid and productive for its legal effects unless the application cancels the agreement.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('الاتصال بنا', 'Contact Us')}>
                        {__(
                            <td>يمكنك التواصل معنا عبر:</td>,
                            <td>You can contact us through:</td>
                        )}
                        <dd>
                            <ul>
                                <li>
                                    {__(
                                        'البريد الإلكتروني  info@awasul.sa',
                                        'Email: info@awasul.sa'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'الاتصال بنا على الرقم +966126825858',
                                        'Call us on the number: +966126825858'
                                    )}
                                </li>
                            </ul>
                        </dd>
                    </Paragraph>
                </Container>
                <Footer />
            </ContentWrapper>
        </ThemeProvider>
    );
};
